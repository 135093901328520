import {mat4, vec3, quat} from 'gl-matrix';

const mtxToStr = (mtx) => {
    return `matrix3d(${mtx[0]}, ${mtx[1]}, ${mtx[2]}, ${mtx[3]}, ${mtx[4]}, ${mtx[5]}, ${mtx[6]}, ${mtx[7]}, ${mtx[8]}, ${mtx[9]}, ${mtx[10]}, ${mtx[11]}, ${mtx[12]}, ${mtx[13]}, ${mtx[14]}, ${mtx[15]})`;
}

export const createTransform = (props) => {
    const {onUpdate: onUpdateFunc} = props;

    return {
        position: vec3.create(),
        rotation: quat.create(),
        scale: vec3.fromValues(1, 1, 1),
        matrix: mat4.create(),
        inverseMatrix: mat4.create(),
        onUpdate: [onUpdateFunc],

        _sendUpdate() {
            if (this.onUpdate) {
                for (let i = 0; i < this.onUpdate.length; i++) {
                    if (this.onUpdate[i])
                        this.onUpdate[i]();
                }
            }
        },

        addOnUpdate(func) {
            this.onUpdate.push(func);
        },

        translate(t){
            vec3.add(this.position, this.position, t);
            this.updateMtx();
        },

        scaleBy(delta) {
            vec3.multiply(this.scale, this.scale, delta);
            // vec3.add(this.scale, this.scale, delta);
            this.updateMtx();
        },

        setPosition(p) {
            this.position[0] = p[0];
            this.position[1] = p[1];
            this.position[2] = p[2];
            this.updateMtx();
        },

        setScale(s) {
            this.scale[0] = s[0];
            this.scale[1] = s[1];
            this.scale[2] = s[2];
            this.updateMtx();
        },

        scaleFromOrigin(s, origin) {
            let tMtx = mat4.create();
            let sMtx = mat4.create();

            if (s > 0.001) {
                mat4.fromScaling(sMtx, vec3.fromValues(s, s, s));
            }
            let originOffset = vec3.fromValues(origin[0], origin[1], origin[2]);
            // vec3.add(originOffset, originOffset, this.position);
            mat4.mul(sMtx, sMtx, this.matrix);
            mat4.fromTranslation(tMtx, originOffset);

            mat4.mul(this.matrix, tMtx, sMtx);
            mat4.getRotation(this.rotation, this.matrix);
            mat4.getTranslation(this.position, this.matrix);
            mat4.getScaling(this.scale, this.matrix);

            // this.updateInverse();

            this._sendUpdate();
        },

        updateMtx() {
            mat4.fromRotationTranslationScale(this.matrix, this.rotation, this.position, this.scale);
            this._sendUpdate();
        },

        updateInverse() {
            mat4.invert(this.inverseMatrix, this.matrix);
        },

        getTransformStr() {
            return mtxToStr(this.matrix);
        },

        getInverseStr() {
            return mtxToStr(this.inverseMatrix);
        }
    }
}