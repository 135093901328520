


export function SaveTheDateController() {
    this.setup = (cvs, canvasUrl, maskUrl, vertexUrl, fragmentUrl) => {
        canvas = cvs;
        canvasImgUrl = canvasUrl;
        maskImgUrl = maskUrl;
        vertexSourceUrl = vertexUrl;
        fragmentSourceUrl = fragmentUrl;

        loadImage();
        
        window.addEventListener("click", handleClick, false);
        window.addEventListener("mousemove", handleMouseMove, false);
        document.addEventListener("touchstart", handleTouch, false);
    }

    this.teardown = () => {
        //window.removeEventListener("load", loadImage, false);
        window.removeEventListener("click", handleClick, false);
        window.removeEventListener("mousemove", handleMouseMove, false);
        document.removeEventListener("touchstart", handleTouch, false);
    }

    let gl;
    let program;
    let image;
    let maskImage;
    let colorTexture;
    let maskTexture;
    let positionLocation;
    let positionBuffer;
    let colorTexLocation;
    let maskTexLocation;
    let waveLocation;
    let growLocation;
    let wave = 0.5;
    let mouseWave = 0.5;
    let dir = 1.0;
    let waveSpeedUpTimer = 0.0;
    let clickCount = 0.0;
    let lastTime = 0.0;
    let canvasImgUrl;
    let maskImgUrl;
    let vertexSourceUrl;
    let fragmentSourceUrl;
    let vertexSource;
    let fragmentSource;
    let canvas;

    function handleClick(evt) {
        doClick();
    }

    function handleTouch(evt) {
        doClick();
    }

    function handleMouseMove(evt) {
        let x = evt.movementX;

        mouseWave -= x * 0.01;
        mouseWave = Math.min(Math.max(0, mouseWave), 1.0);
    }

    function doClick() {
        waveSpeedUpTimer = 1.5;
        clickCount += 0.25;
        if (clickCount > 27.0) {
            clickCount = 27.0;
        }
    }

    function loadImage() {
        image = new Image();
        image.src = canvasImgUrl;
        image.onload = function () {
            loadMask();
        };
    }

    function loadMask() {
        maskImage = new Image();
        maskImage.src = maskImgUrl;
        maskImage.onload = function () {
            loadVertexShader();
        };
    }

    function loadVertexShader() {
        fetch(vertexSourceUrl)
            .then(response => response.text())
            .then((data) => {
                vertexSource = data;
                loadFragmentShader();
            })
    }

    function loadFragmentShader() {
        fetch(fragmentSourceUrl)
            .then(response => response.text())
            .then((data) => {
                fragmentSource = data;
                setupWebGL();
            })
    }

    function setupWebGL() {
        if (!(gl = getRenderingContext())) return;

        const vertexShader = gl.createShader(gl.VERTEX_SHADER);
        gl.shaderSource(vertexShader, vertexSource);
        gl.compileShader(vertexShader);

        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
        gl.shaderSource(fragmentShader, fragmentSource);
        gl.compileShader(fragmentShader);

        let fragCompiled = gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS);
        console.log('Shader compiled successfully: ' + fragCompiled);
        let compilationLog = gl.getShaderInfoLog(fragmentShader);
        console.log('Shader compiler log: ' + compilationLog);

        program = gl.createProgram();
        gl.attachShader(program, vertexShader);
        gl.attachShader(program, fragmentShader);
        gl.linkProgram(program);
        gl.detachShader(program, vertexShader);
        gl.detachShader(program, fragmentShader);
        gl.deleteShader(vertexShader);
        gl.deleteShader(fragmentShader);
        if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
            const linkErrLog = gl.getProgramInfoLog(program);
            cleanup();
            console.error(`Shader program did not link successfully. Error log: ${linkErrLog}`);
            return;
        }

        initializeUniforms();
        initializeTextures();
        initializeAttributes();

        render();
    }

    function initializeUniforms() {
        colorTexLocation = gl.getUniformLocation(program, "u_image");
        maskTexLocation = gl.getUniformLocation(program, "u_mask");
        waveLocation = gl.getUniformLocation(program, "u_wave");
        growLocation = gl.getUniformLocation(program, "u_grow");
    }

    function initializeAttributes() {
        gl.enableVertexAttribArray(0);
        positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        var x1 = -1;
        var x2 = 1;
        var y1 = -1;
        var y2 = 1;
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
            x1, y1,
            x2, y1,
            x1, y2,
            x1, y2,
            x2, y1,
            x2, y2,
        ]), gl.STATIC_DRAW);
    }

    function initializeTextures() {
        // Create a texture.
        colorTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, colorTexture);

        // Set the parameters so we can render any size image.
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

        maskTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, maskTexture);

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, maskImage);
    }

    function cleanup() {
        gl.useProgram(null);
        if (positionBuffer) {
            gl.deleteBuffer(positionBuffer);
        }
        if (program) {
            gl.deleteProgram(program);
        }
    }

    function getRenderingContext() {
        // const canvas = document.querySelector("canvas");
        // canvas.width = canvas.clientWidth;
        // canvas.height = canvas.clientHeight;
        const gl =
            canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
        if (!gl) {
            const paragraph = document.querySelector("p");
            paragraph.innerHTML =
                "Failed to get WebGL context." +
                "Your browser or device may not support WebGL.";
            return null;
        }
        gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
        gl.clearColor(0.0, 0.0, 0.0, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT);
        return gl;
    }

    function render(currentTime) {
        let dt = 0.02;
        if (lastTime) {
            dt = (currentTime - lastTime) / 1000;
        }

        lastTime = currentTime;

        if (dt > 0.1) {
            dt = 0.1
        }

        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        gl.useProgram(program);

        gl.enableVertexAttribArray(positionLocation);

        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        const size = 2;
        const type = gl.FLOAT;
        const normalize = false;
        const stride = 0;
        const offset = 0;
        gl.vertexAttribPointer(
            positionLocation, size, type, normalize, stride, offset);

        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, colorTexture);
        gl.uniform1i(colorTexLocation, 0);

        gl.activeTexture(gl.TEXTURE1);
        gl.bindTexture(gl.TEXTURE_2D, maskTexture);
        gl.uniform1i(maskTexLocation, 1);

        if (wave > 1) {
            dir = -1.0;
        }
        else if (wave <= 0) {
            dir = 1.0;
        }

        if (waveSpeedUpTimer > 0) {
            let speed = 5.0;
            waveSpeedUpTimer -= dt;
            wave += dir * speed * dt;
            mouseWave = wave;
        }
        else {
            wave = mouseWave;
        }

        gl.uniform1f(waveLocation, wave - 0.5);

        if (clickCount > 5) {
            gl.uniform1f(growLocation, clickCount - 5);
        }

        const primitiveType = gl.TRIANGLES;
        const count = 6;
        gl.drawArrays(primitiveType, offset, count);

        requestAnimationFrame(render);
    }

    return this;
}