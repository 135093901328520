import "./sticky-note.css";
import clsx from "clsx";
import { useContext, useEffect, useRef, useState } from "react";
import { CanvasContext } from "./canvas-context";
import { vec3 } from "gl-matrix";
import { createTransform } from "./transform";

export function Draggable(props) {
  const ComponentType = props.tag ?? "div";
  const { className, pos: initialPos, zindex, children, style, noShadow, transform: transformProp, ...rest } = props;

  const [isGrabbed, setIsGrabbed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [touchID, setTouchID] = useState(null);
  const dragRef = useRef(null);

  const root = useRef(null);
  const lastPressPos = useRef(null);
  const transform = useRef(null);

  const canvasState = useContext(CanvasContext);

  useEffect(() => {
    if (!transform.current) {
      if (transformProp) {
        transform.current = transformProp;
        transform.current.addOnUpdate(onTransformUpdate);
      } else {
        transform.current = createTransform({ onUpdate: onTransformUpdate });
      }
      transform.current.setPosition([initialPos[0], initialPos[1], 0.0]);
    }
  }, [initialPos, transformProp]);

  const onTransformUpdate = () => {
    root.current.style.transform = transform.current.getTransformStr();
  };

  useEffect(() => {
    const scaleFactor = (isHovered && ComponentType === "button") || isGrabbed ? 1.05 : 1.0;
    transform.current.setScale([scaleFactor, scaleFactor, scaleFactor]);
  }, [ComponentType, isGrabbed, isHovered]);

  const updateMove = (e) => {
    if (e.clientX <= 0 || e.clientY <= 0) return;
    if (canvasState.isZooming) return;
    let p = getCurrPos([e.clientX, e.clientY]);
    if (lastPressPos.current) {
      let move = [p[0] - lastPressPos.current[0], p[1] - lastPressPos.current[1], 0.0];
      transform.current.translate(move);
    }

    lastPressPos.current = [p[0], p[1]];
  };

  const getCurrPos = (p) => {
    let pos = vec3.create();
    vec3.transformMat4(pos, vec3.fromValues(p[0], p[1], 0), canvasState.transform?.inverseMatrix);
    return pos;
  };
  const updateLastPress = (p) => {
    const pos = getCurrPos(p);
    lastPressPos.current = [pos[0], pos[1]];
  };

  const onDragStart = (e) => {
    e.dataTransfer.effectAllowed = "move";
    setIsGrabbed(true);
    e.dataTransfer.setDragImage(dragRef.current, 0, 0);
    updateLastPress([e.clientX, e.clientY]);
  };

  const onDrag = (e) => {
    e.preventDefault();
    updateMove(e);
  };

  const onDragEnd = (e) => {
    onDrag(e);
    setIsGrabbed(false);
  };

  const onTouchStart = (e) => {
    const touches = e.touches;
    const touch = e.targetTouches[0];
    setTouchID(touch.identifier);
    setIsGrabbed(touches.length === 1);
    updateLastPress([touch.clientX, touch.clientY]);
  };

  const onTouchEnd = (e) => {
    setIsGrabbed(false);
  };

  const onTouchMove = (e) => {
    const touches = e.touches;
    if (isGrabbed && touches.length === 1) {
      let touch = null;
      for (let i = 0; i < e.targetTouches.length; i++) {
        if (e.targetTouches[i].identifier === touchID) {
          touch = e.targetTouches[i];
          break;
        }
      }
      if (touch) {
        updateMove(touch);
      }
    } else if (isGrabbed) {
      setIsGrabbed(false);
    }
  };

  const onMouseOver = (e) => {
    setIsHovered(true);
  };

  const onMouseOut = (e) => {
    setIsHovered(false);
  };

  return (
    <ComponentType
      className={clsx(className, noShadow ? null : "shadow", isGrabbed ? "grabbed" : null)}
      ref={root}
      style={{
        position: "absolute",
        zIndex: zindex ?? 10,
        ...style,
      }}
      {...rest}
      draggable={true}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <span
        ref={dragRef}
        style={{
          position: "absolute",
          width: 10,
          height: 10,
          opacity: 0,
        }}
      >
        x
      </span>
      {children}
    </ComponentType>
  );
}
