import { mat4, vec3 } from "gl-matrix"
import { loadVBOFromURL } from "./model-loader.js"


export function OverlayCanvasController() {
    this.setup = (cvs, vertexUrl, fragmentUrl, modelVertexUrl, modelFragmentUrl, modelImg) => {
        canvas = cvs;
        vertexSourceUrl = vertexUrl;
        fragmentSourceUrl = fragmentUrl;

        modelVertexSourceUrl = modelVertexUrl;
        modelFragmentSourceUrl = modelFragmentUrl;
        modelImgUrl = modelImg;

        loadVertexShader();

        // window.addEventListener("click", handleClick, false);
        window.addEventListener("mousemove", handleMouseMove, false);
        // document.addEventListener("touchstart", handleTouch, false);

        window.setInviteTransforms = setInviteTransforms;
    }

    this.teardown = () => {
        //window.removeEventListener("load", loadImage, false);
        // window.removeEventListener("click", handleClick, false);
        window.removeEventListener("mousemove", handleMouseMove, false);
        // document.removeEventListener("touchstart", handleTouch, false);
    }

    this.setModelTransform = (t) => {
        modelTransform = t;
    }

    let gl;
    let program;
    let image;
    let colorTexture;
    let positionLocation;
    let positionBuffer;
    let colorTexLocation;
    let waveLocation;
    let timeLocation;
    let growLocation;
    let resolutionLocation;
    let pointsLocation;
    let timeOffsetsLocation;
    let rootMtxLocation;
    let wave = 0.5;
    let mouseWave = 0.5;
    let dir = 1.0;
    let waveSpeedUpTimer = 0.0;
    let clickCount = 0.0;
    let lastTime = 0.0;
    let modelImgUrl;
    let vertexSourceUrl;
    let fragmentSourceUrl;
    let vertexSource;
    let fragmentSource;
    let canvas;

    let modelVertexSourceUrl;
    let modelFragmentSourceUrl;
    let modelVertexSource;
    let modelFragmentSource;
    let modelMeshes;
    let modelProgram;

    let modelRootMtxLocation;
    let modelTransformLocation;
    let modelResolutionLocation;
    let modelTextureLocation;
    let modelWaveLocation;

    let modelMtx;
    let modelTransform;

    let treeAngle;

    let lastPointGenTime = 0.0;
    let lastPointGenIndex = 0;

    let inviteTransforms;
    let inviteSize;
    let inviteRootPos;

    let points = [
        0.5, 0.5,
        0.25, 0.25,
        0.25, 0.5,
        0.25, 0.75,
        0.5, 0.25,
        0.5, 0.75,
        0.75, 0.25,
        0.75, 0.75,
    ];

    let timeOffsets = [
        0.0,
        0.1,
        0.2,
        0.3,
        0.4,
        0.5,
        0.6,
        0.7
    ];

    let lastMoveLocation = [
        0.0, 0.0
    ];

    let lastUsedMoveLocation = [
        0.0, 0.0
    ]

    function handleClick(evt) {
        doClick();
    }

    function handleTouch(evt) {
        doClick();
    }

    function handleMouseMove(evt) {
        let x = evt.movementX;
        let y = evt.movementY;

        mouseWave -= x * 0.01 + y * 0.01;
        mouseWave = Math.min(Math.max(-1.0, mouseWave), 1.0);


        let p = [evt.clientX, evt.clientY];

        let pos = vec3.create();
        vec3.transformMat4(pos, vec3.fromValues(p[0], p[1], 0), window.rootTransform.inverseMatrix);

        lastMoveLocation[0] = pos[0];
        lastMoveLocation[1] = pos[1];
    }

    function doClick() {
        waveSpeedUpTimer = 1.5;
        clickCount += 0.25;
        if (clickCount > 27.0) {
            clickCount = 27.0;
        }
    }

    function loadImage() {
        image = new Image();
        image.src = modelImgUrl;
        image.onload = function () {
            loadVertexShader();
        };
    }

    function loadVertexShader() {
        fetch(vertexSourceUrl)
            .then(response => response.text())
            .then((data) => {
                vertexSource = data;
                loadFragmentShader();
            })
    }

    function loadFragmentShader() {
        fetch(fragmentSourceUrl)
            .then(response => response.text())
            .then((data) => {
                fragmentSource = data;
                setupWebGL();
            })
    }

    function loadModel() {
        let promise = new Promise((resolve, reject) => {
            image = new Image();
            image.src = modelImgUrl;
            image.onload = function () {
                fetch(modelVertexSourceUrl)
                    .then(response => response.text())
                    .then((data) => {
                        modelVertexSource = data;
                        fetch(modelFragmentSourceUrl)
                            .then(response => response.text())
                            .then((data) => {
                                modelFragmentSource = data;
                                let bufLayout = {
                                    "a_position": [0, 3, 0],
                                    "a_texcoord": [12, 2, 2],
                                    "a_normal": [20, 3, 1],
                                    "a_vid": [32, 1, 3]
                                };
                                loadVBOFromURL(gl, "./palm_tree.vbo", 36, bufLayout).then((mesh) => {
                                    resolve([mesh]);
                                })
                            });
                    })
        }

        });

        return promise;

    }

    function setInviteTransforms(transforms, size, rootPos) {
        inviteTransforms = transforms;
        inviteSize = size;
        inviteRootPos = rootPos;
    }

    function createShader(vSource, fSource) {
        const vertexShader = gl.createShader(gl.VERTEX_SHADER);
        gl.shaderSource(vertexShader, vSource);
        gl.compileShader(vertexShader);
        let shaderLog = gl.getShaderInfoLog(vertexShader);
        if (shaderLog) {
            console.warn(shaderLog);
        }
        // console.warn("vertex compiled: ", gl.getShaderInfoLog(vertexShader));

        const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
        gl.shaderSource(fragmentShader, fSource);
        gl.compileShader(fragmentShader);
        shaderLog = gl.getShaderInfoLog(fragmentShader);
        if (shaderLog) {
            console.warn(shaderLog);
        }

        // let fragCompiled = gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS);
        // console.log('Shader compiled successfully: ' + fragCompiled);
        // let compilationLog = gl.getShaderInfoLog(fragmentShader);
        // console.log('Shader compiler log: ' + compilationLog);

        let outProgram = gl.createProgram();
        gl.attachShader(outProgram, vertexShader);
        gl.attachShader(outProgram, fragmentShader);
        gl.linkProgram(outProgram);
        gl.detachShader(outProgram, vertexShader);
        gl.detachShader(outProgram, fragmentShader);
        gl.deleteShader(vertexShader);
        gl.deleteShader(fragmentShader);
        if (!gl.getProgramParameter(outProgram, gl.LINK_STATUS)) {
            const linkErrLog = gl.getProgramInfoLog(outProgram);
            cleanup();
            console.error(`Shader program did not link successfully. Error log: ${linkErrLog}`);
            return;
        }

        return outProgram;
    }

    function setupWebGL() {
        if (!(gl = getRenderingContext())) {
            return;
        }
        loadModel().then((meshes) => {
            modelMeshes = meshes;
            modelProgram = createShader(modelVertexSource, modelFragmentSource);
            modelRootMtxLocation = gl.getUniformLocation(modelProgram, "u_rootMtx");
            modelTransformLocation = gl.getUniformLocation(modelProgram, "u_transform");
            modelResolutionLocation = gl.getUniformLocation(modelProgram, "u_resolution");
            modelTextureLocation = gl.getUniformLocation(modelProgram, "u_image");
            modelWaveLocation = gl.getUniformLocation(modelProgram, "u_wave");
            initializeTextures();
        })

        program = createShader(vertexSource, fragmentSource);

        initializeUniforms();
        initializeAttributes();

        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        render();
    }

    function initializeUniforms() {
        colorTexLocation = gl.getUniformLocation(program, "u_image");
        waveLocation = gl.getUniformLocation(program, "u_wave");
        growLocation = gl.getUniformLocation(program, "u_grow");
        timeLocation = gl.getUniformLocation(program, "u_time");
        resolutionLocation = gl.getUniformLocation(program, "u_resolution");
        pointsLocation = gl.getUniformLocation(program, "u_points");
        timeOffsetsLocation = gl.getUniformLocation(program, "u_timeOffsets");
        rootMtxLocation = gl.getUniformLocation(program, "u_rootMtx");
    }

    function initializeAttributes() {
        gl.enableVertexAttribArray(0);
        positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        var x1 = -1;
        var x2 = 1;
        var y1 = -1;
        var y2 = 1;
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([
            x1, y1,
            x2, y1,
            x1, y2,
            x1, y2,
            x2, y1,
            x2, y2,
        ]), gl.STATIC_DRAW);
    }

    function initializeTextures() {
        // Create a texture.
        colorTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, colorTexture);

        // Set the parameters so we can render any size image.
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    }

    function cleanup() {
        gl.useProgram(null);
        if (positionBuffer) {
            gl.deleteBuffer(positionBuffer);
        }
        if (program) {
            gl.deleteProgram(program);
        }
    }

    function getRenderingContext() {
        // const canvas = document.querySelector("canvas");
        // canvas.width = canvas.clientWidth;
        // canvas.height = canvas.clientHeight;
        canvas.width = window.innerWidth * window.devicePixelRatio;
        canvas.height = window.innerHeight * window.devicePixelRatio;
        const gl =
            canvas.getContext("webgl2");
        if (!gl) {
            canvas.style.visibility = "hidden";
            return null;
        }
        gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
        gl.clearColor(0.0, 0.0, 0.0, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT);

        gl.enable(gl.CULL_FACE);
        gl.cullFace(gl.BACK);
        return gl;
    }

    function render(currentTime) {
        let dt = 0.02;
        if (lastTime) {
            dt = (currentTime - lastTime) / 1000;
        }

        lastTime = currentTime;

        let currentTimeS = currentTime * 0.001;

        if (dt > 0.1) {
            dt = 0.1
        }

        if (currentTimeS - lastPointGenTime > 0.1) {
            let nextIndex = (lastPointGenIndex + 1) % 8;

            let hasMatchingPos = false;
            if (Math.abs(lastMoveLocation[0] - lastUsedMoveLocation[0]) > 4 || Math.abs(lastMoveLocation[1] - lastUsedMoveLocation[1]) > 4)
            {
                let p = [lastMoveLocation[0], lastMoveLocation[1]];
                if (inviteTransforms) {
                    let isInside = false;
                    for (let i = 0; i < inviteTransforms.length; i++) {
                        let t = inviteTransforms[i];
                        let tLP = t.position;
                        let tP = [tLP[0] + inviteRootPos[0], tLP[1] + inviteRootPos[1]];
                        if (p[0] > tP[0] && p[1] > tP[1] && p[0] < tP[0] + inviteSize[0] && p[1] < tP[1] + inviteSize[1]) {
                            isInside = true;
                            break;
                        }
                    }

                    if (isInside) {
                        hasMatchingPos = true;
                        points[nextIndex * 2] = p[0];
                        points[nextIndex * 2 + 1] = p[1];
                        lastUsedMoveLocation[0] = p[0];
                        lastUsedMoveLocation[1] = p[1];
                    }
                }

            } 

            if (!hasMatchingPos && inviteTransforms) {
                let randIndex = Math.floor(Math.random() * inviteTransforms.length);
                let t = inviteTransforms[randIndex];
                let tLP = t.position;
                let tP = [tLP[0] + inviteRootPos[0], tLP[1] + inviteRootPos[1]];

                let p = [tP[0] + Math.random() * inviteSize[0], tP[1] + Math.random() * inviteSize[1]];
                // let pos = vec3.create();
                // vec3.transformMat4(pos, vec3.fromValues(p[0], p[1], 0), window.rootTransform.inverseMatrix);
                points[nextIndex * 2] = p[0];
                points[nextIndex * 2 + 1] = p[1];
            }

            timeOffsets[nextIndex] = currentTimeS;
            lastPointGenIndex = nextIndex;
            lastPointGenTime = currentTimeS;
        }

        canvas.width = window.innerWidth * window.devicePixelRatio;
        canvas.height = window.innerHeight * window.devicePixelRatio;

        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

        gl.clearColor(0, 0, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        gl.enable(gl.DEPTH_TEST);
        gl.depthFunc(gl.LESS);

        if (modelProgram) {
            gl.useProgram(modelProgram);
            gl.enableVertexAttribArray(0);
            gl.enableVertexAttribArray(1);
            gl.enableVertexAttribArray(2);
            gl.uniformMatrix4fv(modelRootMtxLocation, false, window.rootTransform.matrix);
            if (modelTransform) {
                gl.uniformMatrix4fv(modelTransformLocation, false, modelTransform.matrix);
            }
            gl.uniform2f(modelResolutionLocation, gl.canvas.width / window.devicePixelRatio, gl.canvas.height / window.devicePixelRatio);

            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, colorTexture);
            gl.uniform1i(modelTextureLocation, 0);
            gl.uniform1f(modelWaveLocation, mouseWave);

            for( let i = 0; i < modelMeshes.length; i++) {
                modelMeshes[i].render();
            }
        }

        gl.useProgram(program);

        gl.enableVertexAttribArray(positionLocation);

        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

        const size = 2;
        const type = gl.FLOAT;
        const normalize = false;
        const stride = 0;
        const offset = 0;
        gl.vertexAttribPointer(positionLocation, size, type, normalize, stride, offset);

        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, colorTexture);
        gl.uniform1i(colorTexLocation, 0);


        // if (wave > 1) {
        //     dir = -1.0;
        // }
        // else if (wave <= 0) {
        //     dir = 1.0;
        // }

        // if (waveSpeedUpTimer > 0) {
        //     let speed = 5.0;
        //     waveSpeedUpTimer -= dt;
        //     wave += dir * speed * dt;
        //     mouseWave = wave;
        // }
        // else {
        //     wave = mouseWave;
        // }

        // gl.uniform1f(waveLocation, wave - 0.5);
        gl.uniform1f(timeLocation, currentTime * 0.001);
        gl.uniform2f(resolutionLocation, gl.canvas.width / window.devicePixelRatio, gl.canvas.height / window.devicePixelRatio);
        gl.uniform2fv(pointsLocation, points);
        gl.uniform1fv(timeOffsetsLocation, timeOffsets);
        gl.uniformMatrix4fv(rootMtxLocation, false, window.rootTransform.matrix);

        // if (clickCount > 5) {
        //     gl.uniform1f(growLocation, clickCount - 5);
        // }

        const primitiveType = gl.TRIANGLES;
        const count = 6;
        gl.drawArrays(primitiveType, offset, count);

        requestAnimationFrame(render);
    }

    return this;
}