import "./invitation.css"
import invite0Img from "../assets/invites/invite_0.png";
import invite1Img from "../assets/invites/invite_1.png";
import invite2Img from "../assets/invites/invite_2.png";
import invite3Img from "../assets/invites/invite_3.png";
import invite4Img from "../assets/invites/invite_4.png";
import invite5Img from "../assets/invites/invite_5.png";
import { Draggable } from "../draggable";
import {createTransform} from "../transform";
import {useEffect, useRef, useState} from "react";

export function Invitation(props) {

    const {pos} = props;
    const [inviteUrls, setInviteUrls] = useState(["", "", ""]);
    const [updateCount, setUpdateCount] = useState(0);

    const inviteTransforms = useRef(null);

    useEffect(() => {
        if (!inviteTransforms.current) {
            inviteTransforms.current = [];
            for (let i = 0; i < 3; i++) {
                inviteTransforms.current.push(createTransform({ onUpdate: onTransformUpdate }));
            }
        }
    })

    useEffect(() => {
        let inviteUrls = []
        const invites = [invite0Img, invite1Img, invite2Img, invite3Img, invite4Img, invite5Img];
        for (let i = 0; i < 3; i++) {
            const index = (Math.floor(Math.random() * invites.length));
            inviteUrls.push(invites[index]);
            invites.splice(index, 1);
        }
        setInviteUrls(inviteUrls);
        
    }, [])

    const handleClicked = (i) => {
      const invites = [invite0Img, invite1Img, invite2Img, invite3Img, invite4Img, invite5Img];
      const index = Math.floor(Math.random() * invites.length);
      setInviteUrls(urls => {
        urls[i] = invites[index];
        return urls;
      });
      setUpdateCount(count => count + 1);
    }

    const onTransformUpdate = () => {
      if (window.setInviteTransforms)
        window.setInviteTransforms(inviteTransforms.current, [637, 825], pos);
    };

    return (
      <div
        style={{
          transform: `translate(${pos[0]}px, ${pos[1]}px)`,
          position: "absolute",
        }}
      >
        {inviteTransforms.current && (
          <div>
            <Draggable transform={inviteTransforms.current[0]} pos={[500, 80]} width={"400px"} className="poster" onClick={() => handleClicked(0)}>
              <img src={inviteUrls[0]} alt="invitation" className="posterImg" />
            </Draggable>
            <Draggable transform={inviteTransforms.current[1]} pos={[50, 20]} width={"400px"} className="poster" onClick={() => handleClicked(1)}>
              <img src={inviteUrls[1]} alt="invitation" className="posterImg" />
            </Draggable>
            <Draggable transform={inviteTransforms.current[2]} pos={[270, 120]} width={"400px"} className="poster" onClick={() => handleClicked(2)}>
              <img src={inviteUrls[2]} alt="invitation" className="posterImg" />
            </Draggable>
          </div>
        )}
      </div>
    );
}