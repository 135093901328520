import { NoteText, StickyNote } from "./sticky-note";
import { FloatingText } from "./floating-text";
import { getDatabase, ref, set } from "firebase/database";
import { useRef, useState } from "react";
import img00 from "./assets/img/00.jpg";
import { Draggable } from "./draggable";

export function RSVP(props) {
  const nameRef = useRef(null);
  const plusOneRef = useRef(null);
  const emailRef = useRef(null);
  const telRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);

  const { pos } = props;

  const writeRSVP = (name, plusOne, email, tel) => {
    const db = getDatabase();
    set(ref(db, "rsvps/" + btoa(email)), {
      name: name,
      email: email,
      plusOne: plusOne,
      tel: tel,
    }).then(() => {
      setSubmitted(true);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    writeRSVP(nameRef.current.value, plusOneRef.current.value, emailRef.current.value, telRef.current.value);
  };

  const onDragEvent = (e) => {
    e.preventDefault();
  };

  return (
    <div
      onDragOver={onDragEvent}
      onDragEnter={onDragEvent}
      onDragExit={onDragEvent}
      style={{
        transform: `translate(${pos[0]}px, ${pos[1]}px)`,
      }}
    >
      <Draggable pos={[800, 200]} className="photo">
        <img src={img00} alt="us" width={200} />
      </Draggable>
      <form onSubmit={onSubmit}>
        <StickyNote color="newYellow" pos={[219, 179]} circle extraSmall>
          <NoteText title>RSVP</NoteText>
        </StickyNote>
        <FloatingText pos={[348, 204]} width={213} zindex={5} noShadow>
          <div>WE ARE HAPPY TO HAVE YOU! PLEASE ENTER YOUR INFORMATION BELOW</div>
          <br />
          <div>PLEASE RSVP BY SEPTEMBER 30, 2023</div>
        </FloatingText>
        <StickyNote color="newYellow" pos={[183, 305]}>
          <NoteText>
            <div>
              Name <input type="text" required ref={nameRef}></input>
            </div>
            <div>
              +1? <input type="text" ref={plusOneRef}></input>
            </div>
          </NoteText>
        </StickyNote>
        <StickyNote color="newYellow" pos={[395, 449]} zindex={5}>
          <NoteText>
            <div>
              Email <input type="email" required ref={emailRef}></input>
            </div>
            <div>
              Tel <input type="tel" ref={telRef}></input>
            </div>
          </NoteText>
        </StickyNote>
        <StickyNote color="newRed" pos={[645, 586]} circle extraSmall tag="button">
          <NoteText title>submit</NoteText>
        </StickyNote>
        {submitted && (
          <StickyNote color="newBlue" pos={[540, 200]}>
            <NoteText> great! we are excited to have you!</NoteText>
          </StickyNote>
        )}
      </form>
    </div>
  );
}
