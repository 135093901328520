import { useEffect, useCallback, useState, useRef } from "react";
import { RSVP } from "./rsvp";
import { useNavigate } from "react-router-dom";
import { CanvasContext } from "./canvas-context";
import { Invitation } from "./invitation/invitation";
import { PostWedding } from "./post-wedding/post-wedding";
import { SaveTheDateContainer } from "./save-the-date-container";
import { HonoluluRecs } from "./honolulu-recs";
import { StickyNote, NoteText } from "./sticky-note";
import { vec3, mat4 } from "gl-matrix";

import clsx from "clsx";

import img01 from "./assets/img/01.jpg";
import img02 from "./assets/img/02.jpg";

import { ReactComponent as TitleSVG } from "./assets/home/title.svg";
import { ReactComponent as InvitationSVG } from "./assets/home/invitation.svg";
import { ReactComponent as RSVPSVG } from "./assets/home/rsvp.svg";
import { ReactComponent as HonoluluSVG } from "./assets/home/honolulu.svg";
import { ReactComponent as SaveTheDateSVG } from "./assets/home/save-the-date.svg";
import { ReactComponent as PostWeddingSVG } from "./assets/home/post-wedding.svg";
import { ReactComponent as ItinerarySVG } from "./assets/home/itinerary.svg";
import { ReactComponent as RegistrySVG } from "./assets/home/registry.svg";
import { ReactComponent as RegistryCardSVG } from "./assets/home/registry-card.svg";

import { Draggable } from "./draggable";
import "./home.css";
import { ComingSoon } from "./coming-soon";
import { createTransform } from "./transform";
import { OverlayCanvasController } from "./overlay-canvas/overlay-canvas-controller";
import fragmentShader from "./overlay-canvas/fragment.glsl";
import vertexShader from "./overlay-canvas/vertex.glsl";
import modelFragmentShader from "./overlay-canvas/modelFragment.glsl";
import modelVertexShader from "./overlay-canvas/modelVertex.glsl";
import { Itinerary } from "./itinerary/itinerary";

import palikuImg from "./assets/img/paliku.jpg";

let lastTime = 0;
let currVelocity = [0, 0];
let currAnimID = null;
let scaleVelocity = 0.0;
let deltaTime = 0.01;
let posBuffer = [null, null, null, null, null, null, null, null, null, null, null, null];
let posBufferIndex = 0;
let posBufferCount = 0;
//let currPos = [0, 0];
let hasTargetPos = false;
let targetPos = [0, 0];
let maxPos = [1000, 1000];
let targetScale = 1.0;
let lastTouchDist = -1;

let lastPressPos = null;

let isDragging = false;
let isCurrZooming = false;

let isFirstLoad = true;

const setHasTargetPos = (hasTarget) => {
  hasTargetPos = hasTarget;
};

const setTargetPos = (pos) => {
  targetPos = pos;
};

const setMaxPos = (pos) => {
  maxPos = pos;
};

const setTargetScale = (scale) => {
  targetScale = scale;
};

const setIsDragging = (drag) => {
  isDragging = drag;
};

const HomeButton = (props) => {
  const { pos, className, ...rest } = props;
  return <Draggable className={clsx(className, "homeButton")} noShadow pos={pos} {...rest} />;
};

export function Home(props) {
  const { page } = props;

  const overlayCanvasRef = useRef(null);

  const [isZooming, setIsZooming] = useState(false);

  const [rsvpPos, setRSVPPos] = useState([0, 0]);
  const [invitePos, setInvitePos] = useState([0, 0]);
  const [postWeddingPos, setPostWeddingPos] = useState([0, 0]);
  const [saveTheDatePos, setSaveTheDatePos] = useState([0, 0]);
  const [calgaryRecsPos, setCalgaryRecsPos] = useState([0, 0]);
  const [itineraryPos, setItineraryPos] = useState([0, 0]);

  const [hasPositions, setHasPositions] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [showRegistryCard, setShowRegistryCard] = useState(null);

  const [showComingSoon, setShowComingSoon] = useState(null);
  const [showComingSoon2, setShowComingSoon2] = useState(null);

  const canvasRoot = useRef(null);
  const rootTransform = useRef(null);
  const modelTransform = useRef(null);

  const originalScale = useRef(null);
  const zoomKeyPressed = useRef(false);

  const portraitMatches = window.matchMedia("(orientation: portrait)"); // !!window.screen && window.screen.height > window.screen.width;
  const isPortraitMode = portraitMatches?.matches;

  const navigate = useNavigate();

  const onDragOverEvent = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!rootTransform.current) {
      rootTransform.current = createTransform({ onUpdate: onTransformUpdate });
      rootTransform.current.setPosition([targetPos[0], targetPos[1], 0]);
      rootTransform.current.setScale([targetScale, targetScale, targetScale]);
      window.rootTransform = rootTransform.current;
    }
  });

  const onTransformUpdate = () => {
    if (canvasRoot.current && rootTransform.current) {
      rootTransform.current.updateInverse();
      canvasRoot.current.style.transform = rootTransform.current.getTransformStr();
    }
  };

  const onUpdate = useCallback(() => {
    currAnimID = requestAnimationFrame(onUpdate);

    let timeDiff = 0;
    let currTime = Date.now();
    if (lastTime) {
      timeDiff = currTime - lastTime;
    }
    lastTime = currTime;

    const dt = timeDiff * 0.001;

    deltaTime = dt;

    const stiffness = 60.0;
    const dampening = 12.0;

    const scaleStiffness = 100.0;
    const scaleDampening = 20.0;

    let target = null;

    let currPos = rootTransform.current.position; // vec3.create();
    let currScale = rootTransform.current.scale[0];

    const aspect = window.innerHeight / window.innerWidth;
    let scaleProportion = currScale / originalScale.current;
    let maxP = [maxPos[0] * scaleProportion, maxPos[1] * scaleProportion];

    if (isDragging || isCurrZooming) {
    } else if (hasTargetPos) {
      // let scaleOffset = rootTransform.current.scale[0] - 1.0;
      target = [targetPos[0], targetPos[1]];
    } else if (currPos[0] > 0 || currPos[1] > 0 || currPos[0] < -maxP[0] || currPos[1] < -maxP[1]) {
      const clampedPos = [Math.max(Math.min(currPos[0], 0), -maxP[0]), Math.max(Math.min(currPos[1], 0), -maxP[1])];
      target = [clampedPos[0], clampedPos[1]];
      setHasTargetPos(true);
      setTargetPos(target);
    }

    if (target) {
      const stretch = [target[0] - rootTransform.current.position[0], target[1] - rootTransform.current.position[1]];
      const accel = [stretch[0] * stiffness - currVelocity[0] * dampening, stretch[1] * stiffness - currVelocity[1] * dampening];
      currVelocity = [currVelocity[0] + accel[0] * dt, currVelocity[1] + accel[1] * dt];

      const scaleStretch = targetScale - currScale;
      const scaleAccel = scaleStretch * scaleStiffness - scaleVelocity * scaleDampening;
      scaleVelocity = scaleVelocity + scaleAccel * dt;

      const prevScale = rootTransform.current.scale[0];
      const newScale = prevScale + scaleVelocity * dt;

      const scaleOffset = newScale / prevScale;
      rootTransform.current.scaleBy([scaleOffset, scaleOffset, scaleOffset]);
    } else {
      const drag = 1.0;
      currVelocity = [currVelocity[0] - currVelocity[0] * drag * dt, currVelocity[1] - currVelocity[1] * drag * dt];
    }

    rootTransform.current.translate([currVelocity[0] * dt, currVelocity[1] * dt, 0]);
  }, []);

  useEffect(() => {
    const update = onUpdate;
    if (update) {
      currAnimID = requestAnimationFrame(update);
      return () => {
        cancelAnimationFrame(currAnimID);
      };
    }
  }, [onUpdate]);

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const scaleX = width / 1100.0;
    const scaleY = height / 1100.0;
    const scale = Math.min(scaleX, scaleY);

    let usedWidth = Math.max(width, 1500 * scale);
    const stdX = usedWidth; // - 150*scale;
    const invX = stdX + width; // - 150 * scale;
    setRSVPPos([0, height]);
    setInvitePos([stdX, 0]);
    // setInvitePos([invX, 0]);
    setItineraryPos([invX, 0]);
    setPostWeddingPos([width, height]);
    // setInvitePos([width, height]);
    setSaveTheDatePos([width * 0.8, height * 1.8]);
    setTargetScale(scale);
    originalScale.current = scale;
    setMaxPos([usedWidth + width * ((scaleY / scale) * 1.5), height * (1.0 + (scaleX / scale) * 0.5)]);
    setCalgaryRecsPos([invX, height]);
    setHasPositions(true);

    // modelTransform.current.setPosition(invX, height, 0.0);
  }, [setRSVPPos, setInvitePos, setPostWeddingPos, page]);

  const updateTargetPosForPage = useCallback(() => {
    let jumpToScale = true;
    let pos = [0, 0];
    if (page === "save-the-date") {
      pos = [-saveTheDatePos[0], -saveTheDatePos[1]];
    } else if (page === "rsvp") {
      pos = [-rsvpPos[0], -rsvpPos[1]];
      // setCurrPos([-rsvpPos[0], -rsvpPos[1]]);
    } else if (page === "invitation") {
      pos = [-invitePos[0], -invitePos[1]];
    } else if (page === "post-wedding") {
      pos = [-postWeddingPos[0], -postWeddingPos[1]];
    } else if (page === "honolulu-recs") {
      pos = [-calgaryRecsPos[0], -calgaryRecsPos[1]];
    } else if (page === "itinerary") {
      pos = [-itineraryPos[0], -itineraryPos[1]];
    } else {
      pos = [0, 0];
      // jumpToScale = false;
    }

    setTargetPos(pos);
    setTargetScale(originalScale.current);

    if (hasPositions) {
      if (!hasLoaded && rootTransform.current) {
        setHasLoaded(true);
        rootTransform.current.setPosition([pos[0], pos[1], 0]);
      } else {
        setHasTargetPos(true);
      }
    }

    if (jumpToScale) {
      if (rootTransform.current) {
        const prevScale = rootTransform.current.scale[0];
        const scaleOffset = targetScale / prevScale;
        const scaleMult = (targetScale - prevScale) / prevScale;
        const posOffset = [-window.innerWidth * 0.5 * scaleMult, -window.innerHeight * 0.5 * scaleMult, 0];

        rootTransform.current.scaleFromOrigin(scaleOffset, posOffset);
      }
    }
  }, [page, saveTheDatePos, rsvpPos, invitePos, postWeddingPos, calgaryRecsPos, hasPositions, hasLoaded]);

  useEffect(() => {
    updateTargetPosForPage();
  }, [updateTargetPosForPage]);

  const handleSelectRSVP = useCallback(() => {
    navigate("/rsvp");
    updateTargetPosForPage();
  }, [navigate, updateTargetPosForPage]);

  const handleSelectInvitation = useCallback(() => {
    navigate("/invitation");
    updateTargetPosForPage();
  }, [navigate, updateTargetPosForPage]);

  const handleSelectPostWedding = useCallback((e) => {
    navigate("/post-wedding");
    updateTargetPosForPage();
    // let pos = vec3.create();
    // vec3.transformMat4(pos, vec3.fromValues(e.clientX, e.clientY, 0), rootTransform.current.inverseMatrix);
    // setShowComingSoon2([pos[0], pos[1]]);
  }, [navigate, updateTargetPosForPage]);

  const handleSelectSaveTheDate = useCallback(() => {
    navigate("/save-the-date");
    updateTargetPosForPage();
  }, [navigate, updateTargetPosForPage]);

  const handleGoToCalgaryRecs = useCallback(() => {
    navigate("/honolulu-recs");
    updateTargetPosForPage();
  }, [navigate, updateTargetPosForPage]);

  const handleGoToItinerary = useCallback((e) => {
    navigate("/itinerary");
    updateTargetPosForPage();
    // let pos = vec3.create();
    // vec3.transformMat4(pos, vec3.fromValues(e.clientX, e.clientY, 0), rootTransform.current.inverseMatrix);
    // setShowComingSoon([pos[0], pos[1]]);
  }, [navigate, updateTargetPosForPage]);

  const handleSelectRegistry = useCallback(
    (e) => {
      let pos = vec3.create();
      vec3.transformMat4(pos, vec3.fromValues(e.clientX, e.clientY - 500, 0), rootTransform.current.inverseMatrix);
      setShowRegistryCard([pos[0], pos[1]]);
    },
    [setShowRegistryCard]
  );

  const onKeyDown = (ev) => {
    const key = ev.key;
    if (key === "Meta") {
      zoomKeyPressed.current = true;
    }
  };

  const onKeyUp = (ev) => {
    const key = ev.key;
    if (key === "Meta") {
      zoomKeyPressed.current = false;
    }
  };

  useEffect(() => {
    const keyDownEv = document.addEventListener("keydown", onKeyDown);
    const keyUpEv = document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", keyDownEv);
      document.removeEventListener("keyup", keyUpEv);
    };
  }, []);

  const onScroll = (ev) => {
    if (zoomKeyPressed.current) {
      const px = ev.clientX;
      const py = ev.clientY;

      updateZoom(ev.deltaY * -0.001, px, py);
    } else {
      const move = [-ev.deltaX, -ev.deltaY, 0];
      rootTransform.current.scaleFromOrigin(0.0, move);
      setHasTargetPos(false);
    }
  };

  const updateDrag = (pos) => {
    if (pos[0] <= 0 || pos[1] <= 0) return;

    if (lastPressPos) {
      let move = [pos[0] - lastPressPos[0], pos[1] - lastPressPos[1], 0.0];
      rootTransform.current.scaleFromOrigin(0.0, move);
      setHasTargetPos(false);
    }

    lastPressPos = [pos[0], pos[1]];
  };

  function updateScale(newScale, px, py) {
    const prevScale = rootTransform.current.scale[0];

    // Restrict scale
    const canvasScale = Math.min(Math.max(0.25, newScale), 8);

    const scaleChange = canvasScale / prevScale;
    const scaleMult = (canvasScale - prevScale) / prevScale;
    const aspect = window.innerWidth / window.innerHeight;
    let offsetPos = [-px * scaleMult, -py * scaleMult, 0.0];

    if (scaleChange !== 1.0) {
      setHasTargetPos(false);
      setTargetScale(canvasScale);
      currVelocity = [0.0, 0.0];
      //rootTransform.current.translate([0, 0, 1.0 - scaleChange]);

      rootTransform.current.scaleFromOrigin(scaleChange, offsetPos);
    }
  }

  const updateZoom = (delta, px, py) => {
    updateScale(rootTransform.current.scale[0] + delta, px, py);
  };

  const finishDrag = () => {
    // if (!scrolledOrDraggedThisFrame) {
    if (posBufferCount >= posBuffer.length) {
      let end = posBuffer[posBufferIndex];
      let startIndex = (posBufferIndex + 1) % posBuffer.length;
      let start = posBuffer[startIndex];

      let offset = [end[0] - start[0], end[1] - start[1]];
      let totalTime = deltaTime * posBuffer.length;
      let velocity = [offset[0] / totalTime, offset[1] / totalTime];
      currVelocity = velocity;
    }

    for (let i = 0; i < posBuffer.length; i++) {
      posBuffer[i] = null;
    }
    posBufferIndex = 0;
    posBufferCount = 0;
    // }
  };

  const onDragStart = (e) => {
    setIsDragging(true);
    lastPressPos = [e.clientX, e.clientY];
  };

  const onDrag = (e) => {
    e.preventDefault();
    updateDrag([e.clientX, e.clientY]);
  };

  const onDragEnd = (e) => {
    // onDrag(e);
    // setIsDragging(false);
    // console.warn("drag end");

    finishDrag();
    setIsDragging(false);
  };

  const onDocumentTouchStart = (e) => {
    const touches = e.touches;
    if (touches.length === 2) {
      let avgX = (touches[0].clientX + touches[1].clientX) * 0.5;
      let avgY = (touches[0].clientY + touches[1].clientY) * 0.5;
      lastPressPos = [avgX, avgY];
    }
    lastTouchDist = -1;

    isCurrZooming = e.touches.length === 2;
    setIsZooming(isCurrZooming);
  };

  const onDocumentTouchMove = (e) => {
    const touches = e.touches;
    let avgX = 0;
    let avgY = 0;

    if (touches.length === 2) {
      let distX = touches[1].clientX - touches[0].clientX;
      let distY = touches[1].clientY - touches[0].clientY;
      avgX = (touches[0].clientX + touches[1].clientX) * 0.5;
      avgY = (touches[0].clientY + touches[1].clientY) * 0.5;

      let touchDist = Math.sqrt(distX * distX + distY * distY);
      let zoomDelta = 0;
      if (lastTouchDist >= 0) {
        zoomDelta = touchDist - lastTouchDist;
      }

      lastTouchDist = touchDist;

      if (zoomDelta !== 0) {
        updateZoom(zoomDelta * 0.003, avgX, avgY);
      }
      updateDrag([avgX, avgY]);
      isCurrZooming = true;
    } else {
      isCurrZooming = false;
    }
    setIsZooming(isCurrZooming);
  };

  const onDocumentTouchEnd = (e) => {
    isCurrZooming = e.touches.length === 2;
    setIsZooming(isCurrZooming);
  };

  useEffect(() => {
    const startEv = document.addEventListener("touchstart", onDocumentTouchStart, false);
    const moveEv = document.addEventListener("touchmove", onDocumentTouchMove, false);
    const endEv = document.addEventListener("touchend", onDocumentTouchEnd, false);
    return () => {
      document.removeEventListener("touchstart", startEv);
      document.removeEventListener("touchmove", moveEv);
      document.removeEventListener("touchend", endEv);
    };
  }, []);

  useEffect(() => {
    if (overlayCanvasRef.current) {
      modelTransform.current = createTransform({});
      // modelTransform.current.setPosition([1000.0, 0.0, 0.0]);
      const controller = new OverlayCanvasController();
      controller.setup(overlayCanvasRef.current, vertexShader, fragmentShader, modelVertexShader, modelFragmentShader, palikuImg);
      controller.setModelTransform(modelTransform.current);
    }
    if (calgaryRecsPos) {
      modelTransform.current.setPosition([(calgaryRecsPos[0] + window.innerWidth * 0.6) / originalScale.current, (calgaryRecsPos[1] - window.innerHeight * 0.1) / originalScale.current, 0.0]);
      modelTransform.current.setScale([200.0, 200.0, 200.0]);
    }
  }, [overlayCanvasRef, calgaryRecsPos]);

  const onTouchStart = (e) => {
    const touch = e.targetTouches[0];
    const touches = e.touches;

    if (touches.length === 1) {
      setIsDragging(true);
      lastPressPos = [touch.clientX, touch.clientY];
    }
  };

  const onTouchMove = (e) => {
    // e.preventDefault();

    const touches = e.touches;
    if (isDragging && touches.length === 1) {
      const touch = e.targetTouches[0];
      updateDrag([touch.clientX, touch.clientY]);
    } else if (isDragging) {
      setIsDragging(false);
      finishDrag();
    }
  };

  const onTouchEnd = (e) => {
    // onDrag(e);

    setIsDragging(false);
    finishDrag();
  };

  const img0Pos = isPortraitMode ? [530, 900] : [1240, 438];
  const img1Pos = isPortraitMode ? [366, 1000] : [1070, 526];

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100vw",
        overscrollBehavior: "none",
        overscrollBehaviorX: "none",
        margin: 0,
        visibility: hasLoaded ? "visible" : "hidden",
      }}
      onWheel={onScroll}
    >
      <canvas
        ref={overlayCanvasRef}
        style={{
          position: "fixed",
          userSelect: "none",
          pointerEvents: "none",
          zIndex: 1000,
        }}
      ></canvas>
      <div
        draggable={true}
        onDragStart={onDragStart}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        // onMouseUp={onDragEnd}
        style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh" }}
      ></div>

      <div
        onDragOver={onDragOverEvent}
        onDragEnter={onDragOverEvent}
        onDragExit={onDragOverEvent}
        ref={canvasRoot}
        style={{
          transformOrigin: "top left",
          userSelect: "auto",
          pointerEvents: "auto",
        }}
      >
        <CanvasContext.Provider value={{ transform: rootTransform.current, isZooming: isZooming }}>
          <HomeButton pos={[30, 13]}>
            <TitleSVG style={{ width: "200px" }} />
          </HomeButton>

          <HomeButton pos={[262, 174]} tag="button" onClick={handleSelectInvitation}>
            <InvitationSVG style={{ width: "280px" }} />
          </HomeButton>

          <HomeButton pos={[574, 118]} tag="button" onClick={handleSelectRSVP}>
            <RSVPSVG style={{ width: "68px" }} />
          </HomeButton>

          <HomeButton pos={[664, 188]} tag="button" onClick={handleGoToItinerary}>
            <ItinerarySVG style={{ width: "220px" }} />
          </HomeButton>

          <HomeButton pos={[277, 472]} tag="button" onClick={handleSelectPostWedding}>
            <PostWeddingSVG style={{ width: "275px" }} />
          </HomeButton>

          <HomeButton pos={[580, 487]} tag="button" onClick={handleGoToCalgaryRecs}>
            <HonoluluSVG style={{ width: "273px" }} />
          </HomeButton>

          <HomeButton pos={[587, 604]} tag="button" onClick={handleSelectSaveTheDate}>
            <SaveTheDateSVG style={{ width: "275px" }} />
          </HomeButton>

          <HomeButton pos={[358, 688]} tag="button" onClick={handleSelectRegistry}>
            <RegistrySVG style={{ width: "200px" }} />
          </HomeButton>

          <Draggable pos={img0Pos} className="photo">
            <img src={img01} alt="us" width={200} />
          </Draggable>
          <Draggable pos={img1Pos} className="photo">
            <img src={img02} alt="us" width={200} />
          </Draggable>

          {/* {modelTransform.current && <Draggable pos={[modelTransform.current.position[0], modelTransform.current.position[1]]} style={{
            width: "1000px",
            height: "1000px"
          }}>test</Draggable>} */}

          {originalScale.current && (
            <>
              <SaveTheDateContainer pos={[saveTheDatePos[0] / originalScale.current, saveTheDatePos[1] / originalScale.current]} />
              <RSVP pos={[rsvpPos[0] / originalScale.current, rsvpPos[1] / originalScale.current]} />
              <Invitation pos={[invitePos[0] / originalScale.current, invitePos[1] / originalScale.current]} />
              <PostWedding pos={[postWeddingPos[0] / originalScale.current, postWeddingPos[1] / originalScale.current]} />
              <HonoluluRecs pos={[calgaryRecsPos[0] / originalScale.current, calgaryRecsPos[1] / originalScale.current]} />
              <Itinerary pos={[itineraryPos[0] / originalScale.current, itineraryPos[1] / originalScale.current]} />
            </>
          )}

          {showRegistryCard && (
            <Draggable pos={[showRegistryCard[0], showRegistryCard[1]]} noShadow style={{
              width: "300px",
            }}>
              <RegistryCardSVG />
            </Draggable>
          )}

          {showComingSoon && (
            <StickyNote pos={[showComingSoon[0], showComingSoon[1]]} small color="newLightBlue">
              <NoteText title>coming soon</NoteText>
            </StickyNote>
          )}

          {showComingSoon2 && (
            <StickyNote pos={[showComingSoon2[0], showComingSoon2[1]]} small color="newYellow">
              <NoteText>
                <b>coming soon</b>
                <br />
                approx December 31 - January 3
              </NoteText>
            </StickyNote>
          )}
        </CanvasContext.Provider>
      </div>
    </div>
  );
}
