import invite0Img from "../assets/invites/invite_0.png";
import { Draggable } from "../draggable";
import { useState } from "react";
import { StickyNote, NoteText } from "../sticky-note";
import img04 from "../assets/img/04.jpg";

import "./post-wedding.css"
import { FloatingText } from "../floating-text";

export function PostWedding(props) {
  const { pos } = props;
  const [expandBanff, setExpandBanff] = useState(false);

  const handleExpandBanff = () => {
    setExpandBanff((e) => {
      return !e;
    });
  };

  return (
    <div
      style={{
        transform: `translate(${pos[0]}px, ${pos[1]}px)`,
        position: "absolute",
      }}
    >
      <div>
        <StickyNote pos={[70, 335]} small color="newYellow">
          <NoteText>
            <b>{":)"}</b>
          </NoteText>
        </StickyNote>
        <Draggable pos={[53, 313]} className="photo">
          <img src={img04} alt="us" height={200} />
        </Draggable>
        <StickyNote pos={[50, 50]} small color="newYellow">
          <NoteText title>
            <div>post wedding activities</div>
          </NoteText>
        </StickyNote>
        <FloatingText
          pos={[550, 200]}
          noShadow
          style={{
            fontSize: "180px",
          }}
        >
          🐳
        </FloatingText>
        <StickyNote pos={[334, 97]} color="newGrey" long dynamicHeight>
          <NoteText
            left
            top
            textAlign="left"
            style={{
              textTransform: "initial",
              textAlign: "left",
            }}
          >
            <div>
              <div>
                <b>DECEMBER 31ST - JANUARY 2ND</b> (SUGGESTED)
              </div>
              <br />
              <div>
                <b>Below are some scheduled activities we plan to do as a group, please join if you’re interested!</b>
              </div>
              <br />
              <div>
                <div>
                  <b>DECEMBER 31</b>
                  <br />
                  1:30 PM - 6:30 PM
                  <br />
                  NEW YEARS EVE TRIMARAN SAIL, APPROX. $80-$130 PER PERSON
                  <br />
                  <i>SNORKELING, SWIMMING, WHALE WATCHING, AND SUNSET TOUR</i>
                  <ul className="postList">
                    <li>PLEASE EMAIL/TEXT US IF YOU ARE INTERESTED IN JOINING BY SEPTEMBER 30TH</li>
                  </ul>
                </div>
                <br />
                <div>
                  <b>JAUNUARY 1</b>
                  <br />
                  5:30 PM - 9:00 PM
                  <br />
                  NEW YEARS LUAU, APPROX. $140-$165 PER PERSON
                  <br />
                  <i>A TRADITIONAL HAWAIIAN PARTY AND FEAST (DINNER, DRINKS, AND ENTERTAINMENT)</i>
                  <ul className="postList">
                    <li>PLEASE EMAIL/TEXT US IF YOU ARE INTERESTED IN JOINING BY SEPTEMBER 30TH</li>
                  </ul>
                </div>
              </div>
              <br />
              <br />
              <div>
                <b>Other Activities</b>
              </div>
              <br />
              <div>
                <b>KUALOA RANCH</b>
                <br />
                <a href="https://www.kualoa.com/">https://www.kualoa.com/</a>
                <div>
                  Our wedding location is part of a ranch that offers many different activities. If you plan to do any of the available activities (listed on their website), you can use the below code
                  1 week before and 1 week after the wedding date for a 20% off discount.
                  <br />
                  They recommend booking ASAP as tours are selling out 6 weeks in advance.
                  <br />
                  <i style={{ color: "#D60D0D" }}>20% OFF TOURS WITH CODE: SHADIK20</i>
                </div>
              </div>
              <br />
              <b className="postComingSoon">MORE TO COME...</b>
            </div>
          </NoteText>
        </StickyNote>
      </div>
    </div>
  );
}