import './App.css';
import { Home } from "./home";
import { Route, Routes } from "react-router";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { SaveTheDate } from './save-the-date/save-the-date';
import {initializeApp} from "firebase/app";
import { FirebaseDatabaseProvider } from "@react-firebase/database";
import { useEffect } from 'react';


function App() {

  useEffect(() => {
    // https://joshandcorinna-default-rtdb.firebaseio.com/
    const firebaseConfig = {
      apiKey: "AIzaSyDP2PROCNkLFJ3AarT039HGAndnqSK78Ns",
      authDomain: "joshandcorinna.firebaseapp.com",
      databaseURL: "https://joshandcorinna-default-rtdb.firebaseio.com",
      projectId: "joshandcorinna",
      storageBucket: "joshandcorinna.appspot.com",
      messagingSenderId: "887069012984",
      appId: "1:887069012984:web:c20c006b7f857f999ddc0d",
      measurementId: "G-0THVEQP9TD",
    };
    initializeApp(firebaseConfig);
  }, [])

  return (
    <>
      <FirebaseDatabaseProvider>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/save-the-date" element={<Home page="save-the-date" />} />
            <Route path="/rsvp" element={<Home page="rsvp" />} />
            <Route path="/invitation" element={<Home page="invitation" />} />
            <Route path="/post-wedding" element={<Home page="post-wedding" />} />
            <Route path="/honolulu-recs" element={<Home page="honolulu-recs" />} />
            <Route path="/itinerary" element={<Home page="itinerary" />} />
          </Routes>
        </HashRouter>
      </FirebaseDatabaseProvider>
    </>
  );
}

export default App;
