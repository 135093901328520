import "./sticky-note.css";
import clsx from "clsx";
import { useContext, useRef, useState } from "react";
import { CanvasContext } from "./canvas-context";
import { Draggable } from "./draggable";

export function StickyNote(props) {
  // const ComponentType = props.tag ?? "div";
  const { color, small, long, mid, short, circle, extraSmall, dynamicHeight, ...otherProps } = props;

  // const [isGrabbed, setIsGrabbed] = useState(false);
  // const [startPos, setStartPos] = useState(pos);
  // const [currPos, setCurrPos] = useState(pos);
  // const [currPress, setCurrPress] = useState([0, 0]);
  // const dragRef = useRef(null);

  // const canvasState = useContext(CanvasContext);

  // const onDragStart = (e) => {
  //   setCurrPress([e.clientX, e.clientY]);
  //   setStartPos(currPos);
  //   e.dataTransfer.effectAllowed = "move";
  //   setIsGrabbed(true);
  //   e.dataTransfer.setDragImage(dragRef.current, 0, 0);
  // }
  // const onDrag = (e) => {
  //   e.preventDefault();
  //   let move = [e.clientX - currPress[0], e.clientY - currPress[1]];
  //   console.warn("canvas state: ", canvasState)
  //   move[0] /= canvasState.scale;
  //   move[1] /= canvasState.scale;
  //   const newPos = [startPos[0] + move[0], startPos[1] + move[1]];
  //   setCurrPos(newPos);
  // }

  // const onDragEnd = (e) => {
  //   onDrag(e);
  //   setIsGrabbed(false);
  // }

  const className = clsx("stickyNote", color, mid ? "mid" : null, short ? "short" : null, small ? "small" : null, long ? "long" : null, extraSmall ? "extraSmall" : null, circle ? "circle" : null);
  return (
    <Draggable className={clsx(className, dynamicHeight ? "dynamicHeight" : null)} {...otherProps}></Draggable>
    // <ComponentType
    //   className={clsx("stickyNote", color, small ? "small" : null, circle ? "circle" : null, extraSmall ? "extraSmall" : null, isGrabbed ? "grabbed" : null)}
    //   style={{
    //     transform: `translate(${currPos[0]}px, ${currPos[1]}px)`,
    //     zIndex: zindex ?? 10,
    //   }}
    //   {...rest}
    //   draggable={true}
    //   onDragStart={onDragStart}
    //   onDrag={onDrag}
    //   onDragEnd={onDragEnd}
    // >
    //   <span ref={dragRef} style={{
    //     position: "absolute",
    //     width: 10,
    //     height: 10,
    //     opacity: 0,
    //   }}>x</span>
    //   {children}
    // </ComponentType>
  );
}

export function NoteText(props) {
  const { children, className, title, normalWeight, pos, top, bottom, left, right, textAlign, ...otherProps } = props;

  const currPos = pos ?? [0, 0]

  const centerH = (!left && !right);
  const centerV = (!top && !bottom);

  return (
    <div
      className={clsx(className, "noteText", title ? "noteTitle" : null, normalWeight ? "normalWeight" : null, centerV ? "centerV" : null, centerH ? "centerH" : null, top ? "top" : null, bottom ? "bottom" : null, left ? "left" : null, right ? "right" : null)}
      style={{
        transform: `translate(${currPos[0]}px, ${currPos[1]}px)`,
        textAlign: textAlign ?? "center",
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
}
