import { Draggable } from "../draggable";
import { useState } from "react";
import { StickyNote, NoteText } from "../sticky-note";

export function Itinerary(props) {
  const { pos } = props;

  return (
    <div
      style={{
        transform: `translate(${pos[0]}px, ${pos[1]}px)`,
        position: "absolute",
      }}
    >
      <div>
        <StickyNote pos={[50, 50]} small color="newYellow">
          <NoteText title>
            <div>itinerary</div>
          </NoteText>
        </StickyNote>

        <StickyNote pos={[200, 140]} long dynamicHeight color="newRed">
          <NoteText
            left
            top
            textAlign="left"
            style={{
              textTransform: "initial",
              textAlign: "left",
            }}
          >
            <div>
              <b>Our wedding ceremony and dinner celebrations will all be held at Paliku Gardens...</b>
              <br />
              49-560 Kamehameha Highway, Kaneohe, Hawaii 96744
              <br />
              <br />
              <div>
                <b>DECEMBER 30</b>
                <br />
                <br />
                <div>
                  <b>12 PM</b>
                  <br />
                  SHUTTLE PICKUP
                  <br />
                  <i>Ramada Plaza Hotel in Waikiki</i>
                </div>
                <br />
                <div>
                  <b>1 PM</b>
                  <br />
                  ARRIVAL
                  <br />
                  <i>Paliku Gardens</i>
                </div>
                <br />
                <div>
                  <b>1:30 PM</b>
                  <br />
                  TRADITIONAL BRIDE PICKUP CEREMONY
                  <br />
                  <i>Groom's Suite to Bridal Suite</i>
                </div>
                <br />
                <div>
                  <b>2:15 PM</b>
                  <br />
                  TEA CEREMONY (followed by a WELCOME SNACK)
                  <br />
                  <i>Pavillion</i>
                </div>
                <br />
                <div>
                  <b>3:30 PM</b>
                  <br />
                  CEREMONY
                  <br />
                  <i>Water Tower</i>
                </div>
                <br />
                <div>
                  <b>4:00 PM</b>
                  <br />
                  COCKTAIL
                  <br />
                  <i>Bowl Lawn</i>
                </div>
                <br />
                <div>
                  <b>5:30 PM</b>
                  <br />
                  RECEPTION
                  <br />
                  <i>Bowl Lawn</i>
                </div>
                <br />
                <div>
                  <b>10:00 PM</b>
                  <br />
                  END & SHUTTLE PICKUP
                  <br />
                  <i>Paliku Gardens Exit</i>
                </div>
              </div>
              <br />
              <br />
              <div>
                <b>DRESS CODE</b>
                <br />
                <div>CREATIVE BLACK TIE / FORMAL (have fun!)</div>
              </div>
              <br />
              <div>
                <div>Paliku Gardens tropical climate might bring on some rain, please check accordingly / bring anything you might need or want to change into if it does rain.</div>
                <br />
                <div>The gardens have uneven gravel pathways, soil, and grass. Flat, non-stiletto, shoes are suggested.</div>
              </div>
            </div>
          </NoteText>
        </StickyNote>
      </div>
    </div>
  );
}
