import { Draggable } from "./draggable";
import "./floating-text.css";

export function FloatingText(props) {
  const {width, height, ...rest} = props;
  return (
    <Draggable
      className="floatingText"
      style={{
        width: props.width,
        height: props.height,
      }}
      {...rest}
    />
  );
}
