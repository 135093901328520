import { Draggable } from "./draggable";
import { FloatingText } from "./floating-text";
import { SaveTheDate } from "./save-the-date/save-the-date";

export function SaveTheDateContainer(props) {
  const { pos } = props;

  return (
    <div
      style={{
        transform: `translate(${pos[0]}px, ${pos[1]}px)`,
        position: "absolute",
      }}
    >
      <div>
        <FloatingText pos={[200, 200]} noShadow style={{fontSize: "80px"}}>🌋</FloatingText>
        <Draggable pos={[50, 50]} style={{ width: "500px", height: "500px" }}>
          <SaveTheDate />
        </Draggable>
      </div>
    </div>
  );
}
