"use strict";
class Mesh {
    constructor(gl, vertices, indices, stride = 12, layout = { "aPos": [0, 3, 0] }) {
        this.gl = gl;
        this.vertexBuffer = gl.createBuffer();
        this.indexBuffer = gl.createBuffer();
        this.stride = stride;
        this.layout = layout;

        this.elementCount = indices.length;

        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertices), gl.STATIC_DRAW);

        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);

    }

    render() {
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);

        for (var key in this.layout) {
            this.gl.vertexAttribPointer(this.layout[key][2], this.layout[key][1], this.gl.FLOAT, false, this.stride, this.layout[key][0]);
        }

        //gl.vertexAttribPointer(0, 3, gl.FLOAT, false, 0, 0);  
        this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.indexBuffer);
        this.gl.drawElements(this.gl.TRIANGLES, this.elementCount, this.gl.UNSIGNED_SHORT, 0);
    }
}

function loadVBO(gl, buf, stride, layout) {
    var dv = new DataView(buf);

    var vCount = dv.getUint32(0);
    var iCount = dv.getUint32(4);

    var vertices = new Float32Array(buf, 8, vCount * (stride / 4));
    var indices = new Uint32Array(buf, 8 + vCount * stride, iCount);

    return new Mesh(gl, vertices, indices, stride, layout);
}

export function loadVBOFromURL(gl, url, stride, layout) {

    let promise = new Promise((resolve, reject) => {
        var req = new XMLHttpRequest();
        req.onreadystatechange = function () {
            if (req.readyState == req.DONE) {
                if (req.status == 200 && req.response) {
                    resolve(loadVBO(gl, req.response, stride, layout));
                }
            }
        }

        req.open("GET", url, true);
        req.responseType = "arraybuffer";
        req.send();
    })
    return promise;
}

function loadPLY(gl, buf) {
    var dv = new DataView(buf);

    var idx = 0;
    var currLine = "";

    var vertexCount = 0;
    var faceCount = 0;

    while (idx < buf.byteLength) {
        var v = dv.getUint8(idx++);

        if (v == 10) {
            var words = currLine.split(' ');
            if (words[0] == "element") {
                if (words[1] == "vertex") {
                    vertexCount = parseInt(words[2]);
                }
                else if (words[1] == "face") {
                    faceCount = parseInt(words[2]);
                }
            }
            else if (words[0] == "end_header") {
                break;
            }

            currLine = "";
        }
        else {
            currLine += String.fromCharCode(v);
        }

    }

    var vertices = [];
    var indices = [];
    var texcoords = [];

    for (var i = 0; i < vertexCount; ++i) {
        for (var v = 0; v < 3; ++v) {
            vertices.push(dv.getFloat32(idx, true));
            idx += 4;
        }
        idx += 5*4;
    }

    for (var i = 0; i < faceCount; ++i) {
        var el = dv.getUint8(idx++, true);
        for (var j = 0; j < el; ++j) {
            indices.push(dv.getUint32(idx, true));
            idx += 4;
        }

        // el = dv.getUint8(idx++, true);
        // for (var j = 0; j < el; ++j) {
        //     texcoords.push(dv.getFloat32(idx, true));
        //     idx += 4;
        // }
    }

    var vboData = [];
    var iboData = [];

    var maxMeshSize = 1 << 16;
    var meshCount = (indices.length / maxMeshSize) + 1;
    var meshes = [];
    var currIdx = 0;

    var layout = {
        "a_position": [0, 3, 0],
        // "aTexcoord": [12, 2, 1],
        "a_vid": [12, 1, 1]
    };

    for (var i = 0; i < indices.length; ++i) {
        var idx = indices[i];
        vboData.push(vertices[idx * 3]);
        vboData.push(vertices[idx * 3 + 1]);
        vboData.push(vertices[idx * 3 + 2]);
        // vboData.push(texcoords[i * 2]);
        // vboData.push(texcoords[i * 2 + 1]);
        vboData.push(idx * 1.0);

        iboData.push(currIdx);
        ++currIdx;

        if (currIdx == maxMeshSize - 1 || i == indices.length - 1) {

            var mesh = new Mesh(gl, vboData, iboData, 16, layout);
            meshes.push(mesh);
            vboData = [];
            iboData = [];
            currIdx = 0;
        }

    }

    return meshes;
}

export function loadPLYFromFile(gl, url) {
    var req = new XMLHttpRequest();

    let promise = new Promise((resolve, reject) => {
        req.onreadystatechange = function () {
            if (req.readyState == req.DONE) {
                if (req.status == 200 && req.response) {
                    resolve(loadPLY(gl, req.response));
                } else {
                    reject("Error loading PLY file");
                }
            }
        }

        req.open("GET", url, true);
        req.responseType = "arraybuffer";
        req.send();
    });
    
    return promise;
}