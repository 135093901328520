import { StickyNote, NoteText } from "./sticky-note";

import "./honolulu-recs.css";
import { FloatingText } from "./floating-text";

export function HonoluluRecs(props) {
  const { pos } = props;

  return (
    <div
      style={{
        transform: `translate(${pos[0]}px, ${pos[1]}px)`,
        position: "absolute",
      }}
    >
      <div>
        <FloatingText pos={[70, 70]} noShadow style={{
          fontSize: "80px",
        }}>
          🐢
        </FloatingText>
        <StickyNote pos={[50, 50]} small color="newYellow">
          <NoteText title>Honolulu Recs</NoteText>
        </StickyNote>
        <StickyNote pos={[200, 140]} mid dynamicHeight color="newGreen" style={{
          width: "553px",
        }}>
          <NoteText left top textAlign="left">
            <div
              style={{
                textTransform: "initial",
              }}
            >
              <div>
                <b>AIRPORT</b>
                <div>Please fly into Honolulu airport (HNL - Daniel K. Inouye)</div>
              </div>
              <br />
              <div>
                <b>ACCOMODATIONS</b>
                <div>December is a busy month in Hawaii, please look into accommodations as soon as possible.</div>
                <br />
                <div>We recommend staying in Waikiki as it is convenient and close to different activities, shops, and restaurants.</div>
                <br />
                <div>We have looked into the below hotel room block. If you are interested, please follow instructions below to book.</div>
                <br />
                <div>
                  <b>HOTEL BLOCK</b>
                  <ul>
                    <li>
                      RAMADA PLAZA WAIKIKI (<a href="https://ramadaplazawaikiki.com/">https://ramadaplazawaikiki.com/</a>)
                      <ul>
                        <li>BLOCK DATES: 12/25/2023 - 1/3/2024 (FLEXIBLE DATES - PLEASE INQUIRE WHEN BOOKING)</li>
                        <li>$175 + TAXES PER ROOM / PER NIGHT (2 FULL BEDS - UP TO 4 GUESTS)</li>
                        <li>PORTERAGE $13/PER PERSON (ONE TIME FEE)</li>
                        <li>PARKING $15 PER VEHICLE, PER NIGHT</li>
                        <li>
                          BOOKING INSTRUCTIONS:
                          <div>
                            Call (808-744-7561) or email (reservations2@ramadaplazawaikiki.com) to book. Provide group code (122523LAS) or mention "Corinna La Wedding" while booking. (You have until 11/20 to book your room)
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <b>TRANSPORTATION</b>
                <div>We will provide shuttle services on Wedding day to and from Venue. There will be a meeting point in Waikiki for shuttle pick-up and drop off.</div>
                <br />
                <div>
                  You may want a rental car depending on the activities you want to do on the island, but there are plenty of taxi services (including ubers, etc) available. Please note that parking
                  will be difficult on the street, and you may need to inquire about parking with your accomodation.
                </div>
                <br />
                <div>
                  Wedding day venue (Paliku Garden) is 45-60 min drive from Waikiki depending on traffic. It is mostly accessible via provided shuttle bus or by car. Car services such as Uber will be
                  difficult to book in the remote location.
                </div>
              </div>
              <br />
              <div className="recsComingSoon">MORE TO COME...</div>
            </div>
          </NoteText>
        </StickyNote>
      </div>
    </div>
  );
}
