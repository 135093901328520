import { useEffect, useRef } from "react";
import fragment from "./fragment.glsl"
import vertex from "./vertex.glsl"
import img01 from "./01.png"
import img02 from "./02.png"
import img03 from "./03.png"
import canvasImg from "./image_upscaled.jpg";
import maskImg from "./mask.png"

import "./save-the-date.css";
import {SaveTheDateController} from "./save-the-date-controller.js"

export function SaveTheDate() {

    const canvasRef = useRef(null);

    useEffect(() => {
      if (canvasRef.current) {
        const controller = new SaveTheDateController();
        controller.setup(canvasRef.current, canvasImg, maskImg, vertex, fragment);
      }
    }, [canvasRef])

    return (
      <>
        <div className="mainContainer">
          <div className="canvasContainer">
            <div className="topTitleContainer">
              <img className="title1" src={img02} alt="josh & corinna" />
            </div>
            <div className="title3Container">
              <a href="./joshandcorinna.ics" className="saveButton">
                <img className="title3" src={img03} alt="save the date" />
              </a>
            </div>
            <canvas ref={canvasRef} width="768" height="512">
              {" "}
            </canvas>
            <div className="title2Container">
              <img className="title2" src={img01} alt="Calgary, December 30, 2023" />
            </div>
          </div>
        </div>
      </>
    );
}